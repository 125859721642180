import ContainerDividerSpanFirst from "@components/dividers/container-divider-span-first";
import { TestimonialBox } from "@components/sections/testimonials/testimonial-box";
import MaliPetrova from "@images/testimonials/malipetrova.webp";
import { FC } from "react";
import { PagePropsBasicType } from "../../../@types/types-body";
const Testimonials: FC<PagePropsBasicType> = ({ namespace }) => {
  return (
    <section>
      <div className="container mx-auto">
        <ContainerDividerSpanFirst
          id={"testimonials-1"}
          title={"testimonials.title"}
          text={"testimonials.text"}
          span={"testimonials.span"}
          namespace={namespace}
          subtitle={"testimonials.subtitle"}
        />
        <div
          id="testimonials"
          className="flex items-center justify-center w-full px-8 xl:px-0"
        >
          <div className="max-w-6xl mx-auto">
            <div className="flex-col items-center">
              <div className="flex flex-col items-center justify-center max-w-2xl py-8 mx-auto xl:flex-row xl:max-w-full">
                <div className="grid gap-[50px] grid-cols-1  mb-16">
                  <TestimonialBox
                    id={1}
                    namespace={namespace}
                    text={"testimonials.card.first.text"}
                    position={"testimonials.card.first.position"}
                    name={"testimonials.card.first.name"}
                    link={"testimonials.card.first.link"}
                    link_name={"testimonials.card.first.link_name"}
                    photo={MaliPetrova}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
