import {
  i18translateType,
  TestimonialBoxType,
} from "../../../@types/types-body";
import { FC } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";

export const TestimonialBox: FC<TestimonialBoxType> = ({
  id,
  namespace,
  text,
  position,
  name,
                                                         link, link_name,
  photo,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <blockquote
      key={`testimonial-${id}`}
      id={`testimonial-${id}`}
      className="flex flex-col-reverse items-center
    justify-between w-full col-span-1 p-6 text-center transition-all duration-200 shadow-xl bg-gray-50 rounded-lg
        md:flex-row md:text-left hover:bg-white hover:shadow ease"
    >
      <div className="flex flex-col pr-10">
        <div className="relative pl-12">
          <svg
            className="absolute left-12 -top-2 md:top-0 md:left-0 w-4 h-4 md:w-10 md:h-10 fill-crowlyn-button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 125"
          >
            <path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
          </svg>
          <p className="mt-2 text-sm md:text-base text-gray-900 italic">{t(`${text}`)}</p>
        </div>
        <h3 className="pl-12 mt-3 text-base md:text-lg font-medium leading-5 text-crowlyn-mid truncate">
          {t(`${name}`)}
          <span className="mt-1 ml-1 text-base leading-5 text-crowlyn truncate">
            - {t(`${position}`)}
          </span>
        </h3>
        <p className="pl-12 mt-1 text-sm leading-5 text-gray-500 truncate">
          <a href={t(`${link}`)} className="text-xs md:text-sm font-extralight italic leading-5 text-crowlyn-mid truncate" target="_blank" rel="noopener">{t(`${link_name}`)}</a>
        </p>
      </div>
      <div className="flex-shrink-0 object-cover w-24 h-24 mb-5 bg-gray-300 rounded-full shadow-xl md:mb-0 relative">
        <Image
          fill={true}
          className="w-24 h-24 rounded-full"
          src={photo}
          alt={`${t(`${name}`)} - ${t(`${position}`)}`}
        />
      </div>
    </blockquote>
  );
};
